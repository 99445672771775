import * as React from "react"
import PageLayout from "../../components/pageLayout"
import { Input, Select , Button } from "antd"
// markup
const ImportProductsPage = () => {
  const {Option} = Select 
  return (


    <PageLayout>
        <main>
          <Select>
            <Option value="web-site">Sitio web de Price Shoes</Option>
            <Option value="api">API </Option>
          </Select>
             
              <Input placeholder="Url de producto"></Input>
        <Button type="primary">Importar</Button>
        </main>
    </PageLayout>
  )
}

export default ImportProductsPage
 